import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { LoginButton, LoginButtonReturn } from '../styles/LoginButton.styled';
import axios from 'axios';
import styled from 'styled-components';

const ModalDetails = styled(Modal)`
&& .modal-dialog {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;  /* Largura do modal lateral */
  max-width: 500px;
  margin: 0;  /* Remove a margem padrão */
}

&& .modal-content {
  height: 100%;
  border-radius: 0;
  box-shadow: -4px 0px 10px rgba(0, 0, 0, 0.1);  /* Sombra à esquerda */
  border: none;
  padding: 24px 32px;

}

&& .modal-header {
    padding-bottom: 24px;
}

&& .modal-body {
  padding: 20px;
  overflow-y: auto;
}

&& .modal-title {
  font-size: 1.25rem;
}

button {
  margin-top: 20px;
}
.title {
        font-weight: bold;
        font-size: 1.25rem;
        color: #35B7E0;
    }
`;

export const DetailsDataModal = ({show, setShow, children, title}) => {
    const handleClose = () => setShow(false);
    
    return (
        <ModalDetails
        show={show}
        onHide={handleClose}
        dialogClassName="modal-90w" // Modal com 90% da largura
        aria-labelledby="example-custom-modal-styling-title"
        centered
        backdrop="static"  // Desativa o fechamento ao clicar fora
        keyboard={false}
        className="modal-lateral"
      >
        <Modal.Header closeButton>
          <Modal.Title style={{color: '#68686E'}} className='fs-5'>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {children}
        </Modal.Body>
      </ModalDetails>
    );
}
