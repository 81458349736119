import Logo from "../images/t3logo.svg";
import {
  MdSpaceDashboard,
  MdManageSearch,
  MdInventory,
  MdPaid,
  MdStore,
  MdSupportAgent,
  MdLogout,
  MdFlight,
  MdLuggage,
  MdGroup,
  MdDashboard,
  MdTwoWheeler
} from "react-icons/md";
import { DashboardContainer, DashboardContent, MenuAside } from "./styles/MenuAside.styled";
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import { AuthContext } from "../Contexts/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import { StepHeader } from "./StepHeader";
import { Header } from "./Header";
import MenuIcon from "@mui/icons-material/Menu";

export const MenuSideBar = ({
  dashboard,
  search,
  inventory,
  billing,
  profile,
  flight,
  bag,
  support,
  traveler,
  adminDashboard,
  company,
  travels,
  adminSideBar,
  title,
  children,
  searchDelivery
}) => {
  const { user, admin, setUser, setAuth, setAdmin, auth } =useContext(AuthContext);
  const [width, setWidth] = useState(window.innerWidth);
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [itemSelected, setItemSelected] = useState({
    inventory: inventory ? "selected" : "",
    dashboard: dashboard ? "selected" : "",
    support: support ? "selected" : "",
    billing: billing ? "selected" : "",
    profile: profile ? "selected" : "",
    search: search ? "selected" : "",
    flight: flight ? "selected" : "",
    bag: bag ? "selected" : "",
  });

  const MenuItemsControl = () => {
    if (user.hasOwnProperty("cnpj") && !adminSideBar) {
      return (
        <>
          <Link
            to="../search-users"
            className="text-decoration-none"
            relative="path"
          >
            
            <li className={`${itemSelected.search}`}>
            <MdManageSearch />
              <span>Buscar viajante</span>
            </li>
          </Link>
          <Link
            to="../my-sents"
            className="text-decoration-none"
            relative="path"
          >
            <li className={`${itemSelected.inventory}`}>
              <MdInventory />
              <span>Minhas Entregas</span>
            </li>
          </Link>
        </>
      );
    }

    if (
      user.hasOwnProperty("cpf") &&
      !user.hasOwnProperty("cnh") &&
      !adminSideBar
    ) {
      return (
        <>
          <Link
            to="../reg-travel"
            className="text-decoration-none"
            relative="path"
          >
            <li className={`${itemSelected.flight}`}>
              <MdFlight />
              <span>Cadastrar viagem</span>
            </li>
          </Link>
          <Link
            to="../mytravels"
            className="text-decoration-none"
            relative="path"
          >
            <li className={`${itemSelected.bag}`}>
              <MdLuggage />
              <span>Minhas viagens</span>
            </li>
          </Link>
          
        </>
      );
    }

    if(user.hasOwnProperty('cnh'))
    {
      return (
        <>
          <Link
            to="../deliveries"
            className="text-decoration-none"
            relative="path"
          >
            <li className={`${itemSelected.bag}`}>
              <MdTwoWheeler />
              <span>Buscar Viagens</span>
            </li>
          </Link>
          
        </>
      );
    }

    if (admin && adminSideBar) {
      return (
        <>
        <Link
            to="../dashboard"
            className="text-decoration-none"
            relative="path"
          >
            <li className={adminDashboard ? `selected` : ""}>
              <MdDashboard />
              <span>Dashboard</span>
            </li>
          </Link>
          <Link
            to="../travelers"
            className="text-decoration-none"
            relative="path"
          >
            <li className={traveler ? `selected` : ""}>
              <MdGroup />
              <span>Viajantes</span>
            </li>
          </Link>
          <Link
            to="../companies"
            className="text-decoration-none"
            relative="path"
          >
            <li className={company ? `selected` : ""}>
              <MdStore />
              <span>Empresas</span>
            </li>
          </Link>
          <Link
            to="../travels"
            className="text-decoration-none"
            relative="path"
          >
            <li className={travels ? `selected` : ""}>
              <MdFlight />
              <span>Viagens</span>
            </li>
          </Link>
        </>
      );
    }
  };
  const handleToogleMenu = () => {
    setMenuOpen(!menuOpen);
  }
  const handleLogout = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    localStorage.removeItem("token");
    axios
      .post(
        `${
          process.env.NODE_ENV === "development"
            ? process.env.REACT_APP_API_URL_DEV
            : process.env.REACT_APP_API_URL_PROD
        }/login/logout`,
        {},
        config
      )
      .then((res) => {
        setUser([]);
        setAuth(false);
        setAdmin(false);
        navigate('/login');
      });
  };

  return (
    <DashboardContainer className="d-flex position-relative vh-100">
      <MenuAside
        className={width < 1050 ? (menuOpen ? "open" : "closed") : null}
      >
        
        
          {width > 1050 ? (
            <Link className="logo" to="/">
            <img alt="T3 Logo" src={Logo} />
            </Link>
          ) : (
            <div className={`d-flex ${menuOpen ? 'justify-content-between' : 'justify-content-center'}`}>
              <div className={`${menuOpen ? 'd-block' : 'd-none'}`}>
              <Link className="logo" to="/">
            <img alt="T3 Logo" src={Logo} />
            </Link>
              </div>
              
            <button
              onClick={handleToogleMenu}
              className="d-flex align-items-center logout border-0 py-0 px-3 bg-transparent"
            >
              
              <MenuIcon  />
              
            </button>
            </div>
          )}
        

        <ul className="nav flex-column">

          <MenuItemsControl />

          {admin && adminSideBar? (
            ""
          ) : (<>
            <Link
              to="../profile"
              className="text-decoration-none"
              relative="path"
            >
              <li className={`${itemSelected.profile}`}>
                <MdStore />
                <span>Perfil</span>
              </li>
            </Link>
            <Link
            to="../financial"
            className="text-decoration-none"
            relative="path"
          >
            <li className={`${itemSelected.billing}`}>
              <MdPaid />
              <span>Financeiro</span>
            </li>
          </Link>
          </>
          )}
        </ul>

        <ul className="d-flex flex-column mt-auto actions nav ps-0 mt-5">
          <Link
            to="../support"
            className={`text-decoration-none align-items-center support py-0`}
          >
            <li className={itemSelected.support}>
              <MdSupportAgent />
              <span>Suporte</span>
            </li>
          </Link>
          <li>
            <button
              onClick={handleLogout}
              className="d-flex align-items-center gap-3 logout border-0 py-0 bg-transparent"
            >
              <MdLogout />
              Sair
            </button>
          </li>
        </ul>
      </MenuAside>
      <DashboardContent
        style={width < 1050 ? { marginLeft: 65 } : null}
        className="container-fluid px-3 px-md-5 py-4"
      >
        <Header title={title} width={width} />
        <div className="py-3 d-flex flex-column flex-grow-1">{children}</div>
      </DashboardContent>
    </DashboardContainer>
  );
};
