import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';


function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function TabsButtons({rows, setSearch}) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {

    const result = rows.filter(item => item.tipo_status_id === newValue+1);

    setValue(newValue);
    setSearch(result);
  };

  return (
    <Box >
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Aprovados" {...a11yProps(0)} />
          <Tab label="Pendentes" {...a11yProps(1)} />
        </Tabs>
      </Box>
    </Box>
  );
}