import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.js';
import './App.css';
import "react-widgets/styles.css";

import PublicNaviControl from './routes/public.routes.js';
import React from 'react'
import ConfigTravelerControl from './routes/configTraveler.routes.js';
import ConfigCompanyControl from './routes/configCompany.routes.js';
import ConfigDelivererControl from './routes/configDeliverer.routes.js';
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';
import { CompaniesRoutesControl } from './routes/company.routes.js';
import { TravelerRoutes, TravelerRoutesControl } from './routes/traveler.routes.js';
import { ScrollToTop } from './utils/ScrollToTop.js';
import { VerifyEmail } from './pages/VerifyEmail.js';
import { RecoveryPassword } from './pages/RecoveryPassword.js';
import Landing from './pages/Landing.js';
import { About } from './pages/About.js';
import { HowWeWork } from './pages/HowWeWork.js';
import { Contact } from './pages/Contact.js';
import Login from './pages/Login.js';
import { Register } from './pages/Register.js';
import { AdminRoutesController } from './routes/admin.routes.js';
import { DashboardTravelers } from './pages/admin/travelers.js';
import { CompaniesDashboard } from './pages/admin/companies.js';
import { DashboardTravels } from './pages/admin/travels.js';
import { DelivererRoutesControl } from './routes/deliverer.routes.js';
import { Tracking } from './pages/Tracking.js';
import { AdminDashboard } from './pages/admin/dashboard.js';
function App() {

  return (
    <BrowserRouter>
      <React.Fragment>
        <Routes>
          <Route path="/register-traveler" element={<ConfigTravelerControl/>}/>
          <Route path="/register-company" element={<ConfigCompanyControl/>} />
          <Route path="/register-deliverer" element={<ConfigDelivererControl/>} />
          <Route path="/verificar-email" element={<VerifyEmail/>}/>
          <Route path="/recuperar-senha/:token?" element={<RecoveryPassword/>}/>

          <Route path="/companies" >
            <Route  path="search-users" element={ <CompaniesRoutesControl route={'search'}/> } />
            <Route path="profile" element={ <CompaniesRoutesControl route={'profile'}/> } />
            <Route path="my-sents" element={ <CompaniesRoutesControl route={'my-sents'}/> } />
            <Route path="support" element={ <CompaniesRoutesControl route={'support'}/> } />
          </Route>

          <Route path="/" element={<PublicNaviControl/>}>
            <Route path="" element={<Landing/>}/>
            <Route path="about" element={<About/>}/>
            <Route path='how' element={<HowWeWork/>}/>
            <Route path='contact' element={<Contact/>}/>
            <Route path='tracking' element={<Tracking/>}/>
            <Route path="login" element={<Login/>} />
            <Route path="register" element={<Register/>} />
          </Route>

          <Route path="/travelers" element={<Outlet />}>
            <Route path="reg-travel" element={ <TravelerRoutesControl  route={'reg'}/> }/>
            <Route path="profile" element={ <TravelerRoutesControl route={'profile'}/> } />
            <Route path="mytravels" element={ <TravelerRoutesControl route={'mytravels'}/> } />
            <Route path="support" element={ <TravelerRoutesControl route={'support'}/> } />
            <Route path="financial" element={ <TravelerRoutesControl route={'financial'}/> } />
          </Route>

          <Route path="/deliverer" element={<Outlet />}>
            <Route path="deliveries" element={ <DelivererRoutesControl  route={'deliveries'}/> }/>
            <Route path="deliveriesSingle" element={ <DelivererRoutesControl  route={'deliveriesSingle'}/> }/>
            <Route path="profile" element={ <DelivererRoutesControl route={'profile'}/> } />
            <Route path="myDeliveries" element={ <DelivererRoutesControl route={'myDeliveries'}/> } />
            <Route path="support" element={ <DelivererRoutesControl route={'support'}/> } />
            <Route path="financial" element={ <DelivererRoutesControl route={'financial'}/> } />
          </Route>

          <Route path="/admin" element={<AdminRoutesController/>}>
            <Route path="dashboard" element={<AdminDashboard/>}/>
            <Route path="travelers" element={<DashboardTravelers/>}/>
            <Route path="companies" element={<CompaniesDashboard/>}/>
            <Route path="travels" element={<DashboardTravels/>}/>
          </Route>

        </Routes>
      </React.Fragment>
      <ScrollToTop/>
    </BrowserRouter>
  );
}

export default App;
