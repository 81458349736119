import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import { visuallyHidden } from '@mui/utils';
import { useState } from 'react';
import axios from 'axios';
import { useContext } from 'react';
import { AuthContext } from '../../Contexts/AuthContext';
import { DeleteDataModal } from './DeleteDataModal';
import { EditDataModal } from './EditDataModal';
import { DetailsDataModal } from './DetailsDataModal';
import { MdDelete, MdEdit, MdManageSearch } from 'react-icons/md';
import { LoginButtonReturn } from '../styles/LoginButton.styled';
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";

function createData(id, name, cpf, email,) {
  return {
    id,
    name,
    cpf,
    email
  };
}



function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Nome',
  },
  {
    id: 'cpf',
    numeric: false,
    disablePadding: false,
    label: 'CPF',
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: false,
    label: 'E-mail',
  },
  {
    id: 'actions',
    numeric: true,
    disablePadding: false,
    label: 'Ações',
  },
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;
  return (
    <Toolbar
      sx={[
        {
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
        },
        numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        },
      ]}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selecionados
        </Typography>
      ) : (
        <div style={{ flex: '1 1 100%' }}></div>
      )}
      {numSelected > 0 ? (
        <div className='d-flex'>
        <Tooltip title="Deletar">
          <IconButton>
            <DeleteIcon className='text-danger' />
          </IconButton>
        </Tooltip>
      </div>
      ) : (
       null
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};



export default function TableTravelersAdmin({rows, setRows, search}) {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [showDelete, setShowDelete] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [item, setItem] = useState({});
  const [step, setStep] = useState(0);
  const {auth} = useContext(AuthContext);

  const URI = process.env.NODE_ENV === "development" 
  ? process.env.REACT_APP_API_URL_DEV 
  : process.env.REACT_APP_API_URL_PROD;
    const CONFIG = {
        headers: {
            'content-type': 'application/json',
            'Authorization': `Bearer ${auth}`,
            'accept': 'application/json'
        }
    }

  const FIELDS_TRAVELER = [
    'id',
    'cpf',
    'nome',
    'email',
    'telefone',
    'cep',
    'rua',
    'numero',
    'bairro',
    'cidade',
    'estado',
    'data_cadastro',
  ];

  const TRAVELER_EXAMPLE = [
    {
      'cia_aerea': 'GOL',
      'num_voo': 8287272718,
      'localizador': 82828282828,
      'aero_partida': 'Aeroporto de exemplo',
    }
  ];
 
  const handleShowDeleteModal = (id)=>{
    setItem({...item, id:id});
    setShowDelete(true);
  }

  const handleEditModal = (id)=>{
    setShowEdit(true);

    setItem(rows.filter(r=>r.id === id));
  }

  const handleDeleteData = ()=>{
    axios.delete(`${URI}/viajantes/${item.id}`, CONFIG)
    .then((res)=>{
        const removedId = rows.filter(row=>row.id !== item.id);
        setRows(removedId);
        setShowDelete(false);
    })
    .catch((error)=>console.log(error));
  }

  const handleEditData = (e)=>{
      e.preventDefault();
      
      const FILTERED_FIELD = FIELDS_TRAVELER.filter(f=>f!=='id'&&f!=='data_cadastro');
      const fd = new FormData();

      FILTERED_FIELD.map(field=>(fd.append(field, e.target[field].value)));

      axios.patch(`${URI}/viajantes/${item[0].id}`, fd, CONFIG)
      .then(res=>{
          const updatedRows = rows.map((row) => 
            row.id === res.data.id ? res.data : row
          );
          setRows(updatedRows);
          setShowEdit(false);
      })
      .catch(error=>console.log(error));
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      [...rows]
        .sort(getComparator(order, orderBy))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [order, orderBy, page, rowsPerPage, rows],
  );

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <EnhancedTableToolbar numSelected={selected.length} />
        <TableContainer>

          <Table
            sx={{ width: '100%' }}
            aria-labelledby="tableTitle"
            
          >
          
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />

            <TableBody>
              {visibleRows.map((row, index) => {
                const isItemSelected = selected.includes(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                    <>
                  <TableRow
                    
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                    sx={{ cursor: 'pointer' }}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        onClick={(event) => handleClick(event, row.id)}
                        checked={isItemSelected}
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                      />
                      
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                      className={`${row.numero_confirmado_em ? '':'text-warning fw-bold'}`}
                    >
                      {row.nome}
                    </TableCell>
                    <TableCell align="left">{row.cpf}</TableCell>
                    <TableCell align="left">{row.email}</TableCell>
                    <TableCell className='d-flex gap-3'  align="right">
                    <MdManageSearch size={24} style={{cursor: 'pointer'}} className='text-muted' onClick={_=>setShowDetails(true)} />
                    <MdDelete size={24} style={{cursor: 'pointer'}} className='text-danger' onClick={_=>handleShowDeleteModal(row.id)}/>
                    <MdEdit size={24} style={{cursor: 'pointer'}} className='text-info' onClick={_=>handleEditModal(row.id)}/>
                    </TableCell>
                    
                    
                  </TableRow>
                  <DetailsDataModal
                  show={showDetails}
                  setShow={setShowDetails}
                  title="Detalhes do viajante"
                  >
                    {step == 0 ? (
                        <>
                        <div className='d-flex flex-column gap-2 mb-5'>
                    <h6 className='title fs-6 mb-3'>Dados do viajante</h6>
                    <div className='row'>
                        <div className='col-4'>
                          <span style={{fontSize: '0.88rem', fontWeight: 'bolder'}}>Nome completo</span>
                        </div>
                        <div className='col-8'>
                          <span style={{fontSize: '0.88rem'}}>{row.nome}</span>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-4'>
                          <span style={{fontSize: '0.88rem', fontWeight: 'bolder'}}>CPF</span>
                        </div>
                        <div className='col-8'>
                          <span style={{fontSize: '0.88rem'}}>{row.cpf}</span>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-4'>
                          <span style={{fontSize: '0.88rem', fontWeight: 'bolder'}}>E-mail</span>
                        </div>
                        <div className='col-8'>
                          <span style={{fontSize: '0.88rem'}}>{row.email}</span>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-4'>
                          <span style={{fontSize: '0.88rem', fontWeight: 'bolder'}}>Número de celular</span>
                        </div>
                        <div className='col-8'>
                          <span style={{fontSize: '0.88rem'}}>{row.telefone}</span>
                        </div>
                    </div>
                  </div>
                  


                  <div className='d-flex flex-column gap-2 mb-5'>
                    <h6 className='title fs-6 mb-3'>Dados de endereço</h6>
                    <div className='row'>
                        <div className='col-4'>
                          <span style={{fontSize: '0.88rem', fontWeight: 'bolder'}}>CEP</span>
                        </div>
                        <div className='col-8'>
                          <span style={{fontSize: '0.88rem'}}>{row.cep}</span>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-4'>
                          <span style={{fontSize: '0.88rem', fontWeight: 'bolder'}}>Número</span>
                        </div>
                        <div className='col-8'>
                          <span style={{fontSize: '0.88rem'}}>{ row.numero}</span>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-4'>
                          <span style={{fontSize: '0.88rem', fontWeight: 'bolder'}}>Rua</span>
                        </div>
                        <div className='col-8'>
                          <span style={{fontSize: '0.88rem'}}>{row.rua}</span>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-4'>
                          <span style={{fontSize: '0.88rem', fontWeight: 'bolder'}}>Bairro</span>
                        </div>
                        <div className='col-8'>
                          <span style={{fontSize: '0.88rem'}}>{row.bairro}</span>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-4'>
                          <span style={{fontSize: '0.88rem', fontWeight: 'bolder'}}>Cidade</span>
                        </div>
                        <div className='col-8'>
                          <span style={{fontSize: '0.88rem'}}>{row.cidade}</span>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-4'>
                          <span style={{fontSize: '0.88rem', fontWeight: 'bolder'}}>Estado</span>
                        </div>
                        <div className='col-8'>
                          <span style={{fontSize: '0.88rem'}}>{row.estado}</span>
                        </div>
                    </div>
                  </div>
                  <div>
                  <span>
                   
                    { TRAVELER_EXAMPLE.length > 0 ? (
                      <LoginButtonReturn onClick={()=> setStep(1)} className="py-2 px-3 text-nowrap fs-16">
                      Viagens cadastradas <ArrowForwardIosIcon fontSize="24" />
                      </LoginButtonReturn>
                    ) : null

                    }
                
              </span>
                  </div></>
                    ): (
                      <>
                      <div className='mb-5'>
                  <span>
                <LoginButtonReturn onClick={()=> setStep(0)} style={{width: 42}} className="py-1 m-0 px-3 text-nowrap fs-16">
                  <ArrowBackIos fontSize="24" />
                </LoginButtonReturn>
              </span>
                  </div>
                      {TRAVELER_EXAMPLE.map((voo)=>(
                        <div className='d-flex flex-column gap-2 mb-5'>
                    <h6 className='title fs-6 mb-3'>Dados de voo</h6>
                    <div className='row'>
                        <div className='col-4'>
                          <span style={{fontSize: '0.88rem', fontWeight: 'bolder'}}>Aeroporto</span>
                        </div>
                        <div className='col-8'>
                          <span style={{fontSize: '0.88rem'}}>{voo.aero_partida}</span>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-4'>
                          <span style={{fontSize: '0.88rem', fontWeight: 'bolder'}}>Cia aérea</span>
                        </div>
                        <div className='col-8'>
                          <span style={{fontSize: '0.88rem'}}>{voo.cia_aerea}</span>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-4'>
                          <span style={{fontSize: '0.88rem', fontWeight: 'bolder'}}>Número do vôo</span>
                        </div>
                        <div className='col-8'>
                          <span style={{fontSize: '0.88rem'}}>{voo.num_voo}</span>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-4'>
                          <span style={{fontSize: '0.88rem', fontWeight: 'bolder'}}>Localizador</span>
                        </div>
                        <div className='col-8'>
                          <span style={{fontSize: '0.88rem'}}>{voo.localizador}</span>
                        </div>
                    </div>
                  </div>
                      ))}
                      
                  
                      </>

                    )}
                    
                  </DetailsDataModal>
                  </>
                );
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <DeleteDataModal 
          show={showDelete} 
          setShow={setShowDelete} 
          item={item.name} 
          id={item.id}
          handleDeleteData={handleDeleteData}
      />

      <EditDataModal
          handleEditData={handleEditData}
          fields={FIELDS_TRAVELER}
          item={item[0]}
          show={showEdit}
          setShow={setShowEdit}
      />
            
      
    </Box>
  );
}