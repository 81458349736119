import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import {AuthContext} from "../Contexts/AuthContext";
import { useContext } from "react";
import { Profile } from "../pages/Profile";
import { Support } from "../pages/Support";
import { Financial } from "../pages/Financial";
import { Deliveries } from "../pages/deliverer/Deliveries";
import { MyDeliveries } from "../pages/deliverer/MyDeliveries";
import { DeliveriesSingle } from "../pages/deliverer/DeliveriesSingle";

export const DelivererRoutesControl = ({route})=>{
    const {auth, user} = useContext(AuthContext);

    
    if(!auth || user.length === 0){
        return <Navigate to="/login" />;
    }

    if(!user.email_verificado_em){
        return <Navigate to="/verificar-email"/>
    }
    
    if(!user.hasOwnProperty('cpf') && !user.hasOwnProperty('cnh')){
        return <Navigate to="/login" />;
    }
    
    if( 
        user.cep == null 
        || user.cep.length === 0 
        || user.cnh === null
        ){
        return <Navigate to="/register-deliverer"/>
    }
    
    switch(route){
        case 'deliveriesSingle':
            return <DeliveriesSingle/>
        case 'deliveries':
            return <Deliveries/>
        case 'profile':
            return <Profile/>
        case 'myDeliveries':
            return <MyDeliveries/>;
        case 'support':
            return <Support/>
        case 'financial':
            return <Financial/>
        default:
            break;
    }
}