import React, { useContext, useEffect, useState } from 'react'
import { MenuSideBar } from '../../components/MenuSideBar'
import { Header } from '../../components/Header'
import { Paper, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { Table } from 'react-bootstrap'
import { MdDelete, MdEdit } from 'react-icons/md'
import { AuthContext } from '../../Contexts/AuthContext'
import axios from 'axios'
import { DeleteDataModal } from '../../components/admin/DeleteDataModal'
import { EditDataModal } from '../../components/admin/EditDataModal'
import SearchInput from '../../components/forms/SearchInput'
import TableEnterpriseAdmin from '../../components/admin/TableEnterpriseAdmin'

export const CompaniesDashboard = () => {
    const {auth} = useContext(AuthContext);
    const [rows, setRows] = useState([]);
    const [search, setSearch] = useState('');

    const URI = process.env.NODE_ENV === "development" ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PROD;
    const CONFIG = {
        headers: {
            'content-type': 'application/json',
            'Authorization': `Bearer ${auth}`,
            'accept': 'application/json'
        }
    }

    useEffect(_=>{
        if(rows.length <= 0){
            axios.get(`${URI}/empresas`, CONFIG)
            .then(res=>{
                setRows(res.data);
            })
            .catch(error=>console.log(error));
        }
    })

    return (
        
            <MenuSideBar adminSideBar company title={'Admin'}>
            <div className="flex-grow-1 d-flex flex-column" style={{overflowY: 'auto', padding: '2.5rem 1.5rem 3.5rem 3.5rem', backgroundColor: '#F7FAFA'}}>
                <div className='d-flex flex-column' style={{gap: '2.375rem'}}>

                    <div className='row d-flex align-items-end'>
                        <div className='col-12 col-sm-8 col-lg-6'>
                        <SearchInput
                            setSearch={setSearch}
                            rows={rows}
                        />
                        </div>
                        
                    </div>
                    <TableEnterpriseAdmin rows={search !== '' ? search:rows}  setRows={setRows}/>
                </div>
            </div>

            
        </MenuSideBar>
    )
}
