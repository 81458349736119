import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import { styled } from '@mui/material/styles';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { StepperStyle } from './styles/StepHeaderStyle.styled';
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';
import { StepperTrackingStyle } from './styles/TrackingStatusStyle.styled';
import {format} from 'date-fns';

const ColorlibStepIconRoot = styled('div')(({ theme }) => ({
  backgroundColor: '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...theme.applyStyles('dark', {
    backgroundColor: theme.palette.grey[700],
  }),
  variants: [
    {
      props: ({ ownerState }) => ownerState.active,
      style: {
          backgroundColor: '#2B6CBF',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
      },
    },
    {
      props: ({ ownerState }) => ownerState.completed,
      style: {
          backgroundColor: '#2B6CBF'
      },
    },
  ],
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <AirplanemodeActiveIcon />,
    2: <AirplanemodeActiveIcon />,
    3: <AirplanemodeActiveIcon />,
    4: <AirplanemodeActiveIcon />,
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}




const formatDate = (date) => {
  return format(new Date(date), "dd/MM/yy HH:mm");
}

export default function TrackingStatus({steps, getLocal, active}) {
  return (
    <Box sx={{ maxWidth: 400 }}>
      <StepperTrackingStyle activeStep={active} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={index}>
            <StepLabel
            StepIconComponent={ColorlibStepIcon}
            optional={
              <>
                <Typography variant="caption">
                  <p className='m-0'>{getLocal(step.tipo_status.id, step)}</p>
                  <p className='m-0'>Desde: {formatDate(step.created_at)}</p>
                </Typography>
              </>
            }>
              {step.label}
              
            </StepLabel>
            
            <StepContent>
              <Typography>{step.description}</Typography>
            </StepContent>

          </Step>
        ))}
      </StepperTrackingStyle>
    </Box>
  );
}