import React, { useContext, useEffect, useState } from 'react';
import { MenuSideBar } from '../../components/MenuSideBar';
import { AuthContext } from '../../Contexts/AuthContext';
import axios from 'axios';
import SearchInput from '../../components/forms/SearchInput';
import TabsButtons from '../../components/admin/TabsButtons';
import TableTravelAdmin from '../../components/admin/TableTravelsAdmin';

export const DashboardTravels = () => {
    const {auth} = useContext(AuthContext);
    const [rows, setRows] = useState([]);
    const [search, setSearch] = useState('');

    const URI = process.env.NODE_ENV === "development" 
    ? process.env.REACT_APP_API_URL_DEV
    : process.env.REACT_APP_API_URL_PROD;

    const CONFIG = {
        headers: {
            'content-type': 'application/json',
            'Authorization': `Bearer ${auth}`,
            'accept': 'application/json'
        }
    }

    useEffect(_=>{
        if(rows.length <= 0){
            axios.get(`${URI}/cadastro_viagens`, CONFIG)
            .then(res=>{
                setRows(res.data);
            })
            .catch(error=>console.log(error));
        }
    })

    return (
        
        <MenuSideBar adminSideBar travels title={'Admin'}>
            <div className="flex-grow-1 d-flex flex-column" style={{overflowY: 'auto', padding: '2.5rem 1.5rem 3.5rem 3.5rem', backgroundColor: '#F7FAFA'}}>
                <div className='d-flex flex-column' style={{ gap: '2.375rem'}}>

                    <div className='row d-flex align-items-end'>
                        <div className='col-12 col-lg-5 col-xl-6'>
                        <SearchInput
                            setSearch={setSearch}
                            rows={rows}
                        />
                        </div>
                        <div className='col-12 col-sm-6 col-lg-5 col-xl-4'>
                        <TabsButtons 
                            rows={rows}
                            search={search}
                            setSearch={setSearch}
                        />
                        </div>
                    </div>
                    <TableTravelAdmin 
                        rows={search !== '' ? search : rows}  
                        setRows={setRows}
                    />
                </div>
            </div>
            </MenuSideBar>
    );
}
