import React, {useState, useEffect, useContext} from 'react'
import { MenuSideBar } from '../../components/MenuSideBar'
import TimelineIcon from '@mui/icons-material/Timeline';
import axios from 'axios';
import { AuthContext } from '../../Contexts/AuthContext';


export const AdminDashboard = () => {
    const {auth} = useContext(AuthContext);
    const [data, setData] = useState([]);
    const uri = process.env.NODE_ENV === "development" 
    ? process.env.REACT_APP_API_URL_DEV 
    : process.env.REACT_APP_API_URL_PROD;
    const config = {
        headers: {
            'content-type': 'application/json',
            'Authorization': `Bearer ${auth}`,
            'accept': 'application/json'
        }
    };

    useEffect(() => {
      if(data.length === 0)
      {
        axios.get(uri+'/admin/resume', config)
        .then(res=>setData(res.data[0]))
        .catch(error=>error);
      }
    });

    return (
        
            <MenuSideBar adminSideBar adminDashboard title={'Dashboard'}>
            <div className="flex-grow-1 d-flex flex-column" style={{overflowY: 'auto', padding: '2.5rem 1.5rem ', backgroundColor: '#F7FAFA'}}>
                <div className='d-flex flex-column' style={{gap: '2rem', marginTop: '1.5rem'}}>
                    <h3 style={{color: '#4B4B53'}} className='fw-bold'>
                        Cadastros
                    </h3>
                    <div className='row px-3 gap-3'>
                        
                        <div style={{ border: '1px solid #35B7E0'}} className='col px-3 py-4 rounded-2 d-flex flex-column gap-2 shadow-sm'>
                            <h5 style={{color: '#7C7C83'}} className=''>
                                Empresas
                            </h5>
                            <h1 style={{color: '#4B4B53'}} className='fw-bold'>
                                {data.length !== 0 ? data.empresas : '...'}
                            </h1>
                            <span style={{color: '#0CB97B'}}>
                                <TimelineIcon/> 11%
                            </span>
                        </div>

                        <div style={{ border: '1px solid #35B7E0'}} className='col px-3 py-4 rounded-2 d-flex flex-column gap-2 shadow-sm'>
                            <h5 style={{color: '#7C7C83'}} className=''>
                                Viajantes
                            </h5>
                            <h1 style={{color: '#4B4B53'}} className='fw-bold'>
                                {data.length !== 0 ? data.transportadores : '...'}
                            </h1>
                            <span style={{color: '#0CB97B'}}>
                                <TimelineIcon/> 11%
                            </span>
                        </div>

                        <div style={{ border: '1px solid #35B7E0'}} className='col px-3 py-4 rounded-2 d-flex flex-column gap-2 shadow-sm'>
                            <h5 style={{color: '#7C7C83'}} className=''>
                                Entregadores
                            </h5>
                            <h1 style={{color: '#4B4B53'}} className='fw-bold'>
                                {data.length !== 0 ? data.empresas : '...'}
                            </h1>
                            <span style={{color: '#0CB97B'}}>
                                <TimelineIcon/> 11%
                            </span>
                        </div>
                        
                    </div>
                    <h3 style={{color: '#4B4B53'}} className='fw-bold'>
                        Viagens
                    </h3>
                    <div className='row px-3 gap-3'>
                        <div style={{ border: '1px solid #35B7E0'}} className='col px-3 py-4 rounded-2 d-flex flex-column gap-2 shadow-sm'>
                            <h5 style={{color: '#7C7C83'}} className=''>
                                Em análise
                            </h5>
                            <h1 style={{color: '#4B4B53'}} className='fw-bold'>
                                {data.length !== 0 ? data.viagens_em_analise : '...'}
                            </h1>
                        </div>

                        <div style={{ border: '1px solid #35B7E0'}} className='col px-3 py-4 rounded-2 d-flex flex-column gap-2 shadow-sm'>
                            <h5 style={{color: '#7C7C83'}} className=''>
                                Aprovadas
                            </h5>
                            <h1 style={{color: '#4B4B53'}} className='fw-bold'>
                                {data.length !== 0 ? data.viagens_aprovadas : '...'}
                            </h1>
                            
                        </div>

                        <div style={{ border: '1px solid #35B7E0'}} className='col px-3 py-4 rounded-2 d-flex flex-column gap-2 shadow-sm'>
                            <h5 style={{color: '#7C7C83'}} className=''>
                                Recusadas
                            </h5>
                            <h1 style={{color: '#4B4B53'}} className='fw-bold'>
                                {data.length !== 0 ? data.viagens_negadas : '...'}
                            </h1>
                        </div>
                    </div>
                    <h3 style={{color: '#4B4B53'}} className='fw-bold'>
                    Viagens aprovadas
                    </h3>
                    <div className='row px-3 gap-3'>
                        <div style={{ border: '1px solid #35B7E0'}} className='col px-3 py-4 rounded-2 d-flex flex-column gap-2 shadow-sm'>
                            <h5 style={{color: '#7C7C83'}} className=''>
                            Viagem (Contratadas)
                            </h5>
                            <h1 style={{color: '#4B4B53'}} className='fw-bold'>
                                {data.length !== 0 ? data.viagens_contratadas : '...'}
                            </h1>
                        
                        </div>
                        <div style={{ border: '1px solid #35B7E0'}} className='col px-3 py-4 rounded-2 d-flex flex-column gap-2 shadow-sm'>
                            <h5 style={{color: '#7C7C83'}} className=''>
                            Viagem (Em rota de entrega)
                            </h5>
                            <h1 style={{color: '#4B4B53'}} className='fw-bold'>
                                {data.length !== 0 ? data.viagens_em_rota_de_entrega : '...'}
                            </h1>
                        
                        </div>
                        <div style={{ border: '1px solid #35B7E0'}} className='col px-3 py-4 rounded-2 d-flex flex-column gap-2 shadow-sm'>
                            <h5 style={{color: '#7C7C83'}} className=''>
                            Viagem (Em trânsito)
                            </h5>
                            <h1 style={{color: '#4B4B53'}} className='fw-bold'>
                                {data.length !== 0 ? data.viagens_em_transito : '...'}
                            </h1>
                        
                        </div>
                        
                    </div>
                </div>
            </div>
        </MenuSideBar>
    );
}
