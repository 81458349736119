import React, { useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { MdSearch } from 'react-icons/md';

export default function SearchInput({rows, setSearch}) {
  const onChange = (e)=>{
    const result = rows.filter(item =>
      Object.values(item).some(value =>
        String(value).toLowerCase().includes(e.target.value.toLowerCase())
      )
    );

    setSearch(result);
  }


  return (
    <div className="">
      <Form>
        <Form.Group controlId="search">
          <Form.Label>Pesquisa</Form.Label>
          <InputGroup>
            
            <Form.Control
              type="text"
              placeholder="Pesquisa"
              style={{borderRight: 0}}
              className='py-2'
              onChange={onChange}
            />
            <InputGroup.Text style={{backgroundColor: 'white'}} className=''>
              <MdSearch /> {/* Ícone de pesquisa */}
            </InputGroup.Text>
          </InputGroup>
        </Form.Group>
      </Form>
    </div>
  );
}
