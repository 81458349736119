import React, { useContext, useEffect, useState } from 'react';
import { MenuSideBar } from '../../components/MenuSideBar';
import { AuthContext } from '../../Contexts/AuthContext';
import { Header } from '../../components/Header';

import axios from 'axios';
import SearchInput from '../../components/forms/SearchInput';
import TableTravelersAdmin from '../../components/admin/TableTravelersAdmin';

export const DashboardTravelers = () => {
    const {auth} = useContext(AuthContext);
    const [rows, setRows] = useState([]);
    const [search, setSearch] = useState('');

    const URI = process.env.NODE_ENV === "development" 
    ? process.env.REACT_APP_API_URL_DEV 
    : process.env.REACT_APP_API_URL_PROD;
    const CONFIG = {
        headers: {
            'content-type': 'application/json',
            'Authorization': `Bearer ${auth}`,
            'accept': 'application/json'
        }
    }

    useEffect(_=>{
        if(rows.length <= 0){
            axios.get(`${URI}/viajantes`, CONFIG)
            .then(res=>{
                setRows(res.data);
            })
            .catch(error=>console.log(error));
        }
    })

    return (
        
            <MenuSideBar traveler adminSideBar title={'Admin'}>
            <div className="flex-grow-1 d-flex flex-column" style={{overflowY: 'auto', padding: '2.5rem 1.5rem 3.5rem 3.5rem', backgroundColor: '#F7FAFA'}}>
               
                <div className='d-flex flex-column' style={{ gap: '2.375rem'}}>
                    
                    <div className='row d-flex align-items-end'>
                        <div className='col-12 col-sm-8 col-lg-6'>
                        <SearchInput
                            setSearch={setSearch}
                            rows={rows}
                        />
                        </div>
                    </div>

                    <TableTravelersAdmin 
                        rows={search !== '' ? search:rows} 
                        setRows={setRows}
                    />
                </div>
            </div>
            </MenuSideBar>
    );
}